@import "abstracts/variables-bootstrap";
@import "bootstrap";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "partials/fonts";

/** LAYOUT **/
@import "layout/header";
@import "layout/footer";
@import "layout/mainmenu";


/** PAGES **/
@import "pages/cart";
@import "pages/category";
@import "pages/home";
@import "pages/order";
@import "pages/product";

@import "partials/commons";
@import "partials/lazysizes";
/** COMPONENT **/
@import "components/alert";
@import "components/block-social";
@import "components/cart";
@import "components/categories";
@import "components/customer";
@import "components/footer";
@import "components/imageslider";
@import "components/offcanvas";
@import "components/products";
@import "components/searchbar";

@import "components/facet";
@import "components/search-widget";
@import "components/slick";
@import "components/slick-theme";
@import "components/productcomments";
@import "components/utilities";
@import "partials/bs_alpha";

@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&display=swap');

body{
  .page-header h1 {
    color: $primary;
    font-weight: 900;
    text-transform: none;
  }
  .footer__title{
    font-family: $headings-font-family;
    font-size: 1.25rem;
    color: $primary;
    font-weight: 900;
    text-transform: none;
  }
  h1, .h1, .products-section-title{
    text-transform: none;
    color: $primary;
  }
  .vc_btn-green{
    background-color: $primary;
    &:hover{
      background-color: darken($primary, 15%);
    }
  }
  .btn{
    box-shadow: none !important;
  }
  iframe {
    border: 0;
  }
  .cart-detailed-totals{
    .separator{
      border-color: transparent;
    }
  }
  .breadcrumb {
    padding: 0;
    background-color: transparent;
    font-size: .875rem;
  }
  .wpb_text_column{
    ul{
      list-style: disc;
      padding-inline-start: 40px;
    }
    ol{
      padding-inline-start: 40px;
    }
  }
  .logo-bpost img{
    width: 131px;
  }
  .logo-payzen img{
    width: 200px;
  }
}

